// Sofpay intergration

import React, { useEffect } from "react";
import "./sfp.scss";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";

let publicUrl = "https://api.fingenom.com";

function Sfp() {
  const { env, txid } = useParams();

  useEffect(() => {
    console.log(env, txid);
    if (!env || !txid) {
      toast.error("Invalid transaction id");
      return;
    }

    switch (env) {
      case "dev":
        publicUrl = "https://dev.fingenom.com";
        break;
      case "sandbox":
        publicUrl = "https://sandbox.fingenom.com";
        break;
      default:
        publicUrl = "https://api.fingenom.com";
        break;
    }

    getStatus();
  }, []);

  const getStatus = async () => {
    try {
      const response = await fetch(`${publicUrl}/fn-execute/sfp/on-tx-completed/${txid}`, {
        method: "GET",
      });

      const result = await response.json();
      if (!result) return;

      if (result.status === "processing") {
        setTimeout(() => {
          getStatus();
        }, 4000);
      } else {
        window.location.href = result.url;
      }
    } catch (error) {
      toast.error("System Error");
    }
  };

  return (
    <div className="sfp">
      <PuffLoader color="#36d7b7" />
      <h3>Processing...</h3>
    </div>
  );
}

export default Sfp;
