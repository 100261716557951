// Sandbox 3ds intergration

import React, { useEffect, useState } from "react";
import "./threedsv.scss";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import PuffLoader from "react-spinners/PuffLoader";

const publicUrlSandbox = "https://sandbox.fingenom.com";
const publicUrlDev = "https://dev.fingenom.com";

function Threedsv() {
  const { txid } = useParams();

  const [decryptedData, setDecryptedData] = useState();
  const [verificationCode, setVerificationCode] = useState();
  const [publicUrl, setPublicUrl] = useState();
  const [processing, setProcessing] = useState();
  const flows = [
    {
      flow: "Successful",
      code: "1111",
    },
    {
      flow: "Failed",
      code: "2222",
    },
    {
      flow: "Pending",
      code: "3333",
    },
  ];

  const ENVIRONMENT_TYPES = {
    SANDBOX: "sandbox",
    PRODUCTION: "production",
    DEVELOPMENT: "development",
  };

  useEffect(() => {
    if (!txid) {
      toast.error("Invalid transaction id");
      return;
    }
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const transaction = params.get("transaction");

    if (!transaction) {
      toast.error("Invalid transaction id");
      return;
    }

    const bytes = CryptoJS.AES.decrypt(
      transaction.replace(/ /g, "+"),
      "sandbox-transaction"
    );
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    if (!decryptedText) return;

    const _decryptedData = JSON.parse(decryptedText);
    let _publicUrl = publicUrlSandbox;
    if (_decryptedData.environment == ENVIRONMENT_TYPES.DEVELOPMENT)
      _publicUrl = publicUrlDev;
    setPublicUrl(_publicUrl);
    setDecryptedData(_decryptedData);
  }, []);

  const handlePay = async () => {
    const status = getStatusByCode();
    if (status == "pending") {
      pendingFlow();
      return;
    }

    updateTxn(status);
    window.location.href = `${publicUrl}/fn-execute/sandbox/on-tx-completed/${status}/?oid=${txid}`;
  };

  const getStatusByCode = () => {
    switch (verificationCode) {
      case "1111":
        return "succeeded";
      case "2222":
        return "failed";
      case "3333":
        return "pending";
      default:
        return "failed";
    }
  };

  const updateTxn = async (flow) => {
    try {
      const response = await fetch(
        `${publicUrl}/fn-execute/sandbox/on-tx-updated`,
        {
          method: "POST",
          body: JSON.stringify({
            oid: txid,
            status: getStatusByFlow(flow),
            message: flow == "failed" ? "Generic Decline" : "ok",
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.message.error) {
        alert(result.message.error);
        return;
      }
      const redirectUrl = result.message?.redirectUrl;
      if (redirectUrl) {
        window.location.href = result.message.redirectUrl;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getStatusByFlow = (flow) => {
    switch (flow) {
      case "succeeded":
        return "SUCCESS";
      case "failed":
        return "ERROR";
      case "pending":
        return "PENDING_PAYMENT";
      default:
        return "ERROR";
    }
  };

  const pendingFlow = () => {
    setProcessing(true);
    updateTxn("pending");
    setTimeout(() => {
      updateTxn("succeeded");
      window.location.href = `${publicUrl}/fn-execute/sandbox/on-tx-completed/succeeded/?oid=${txid}`;
    }, 5000);
  };

  return (
    <div className="threedsv">
      {processing ? (
        <PuffLoader color="#36d7b7" />
      ) : (
        <div className="container">
          <div className="header">
            <p>3DS Credit Card Verification</p>
          </div>

          <div className="body">
            <p>Fingenom 3DS Verification sandbox</p>
            <div className="row th">
              <span>Flow</span>
              <span>Code</span>
            </div>
            {flows.map((el) => (
              <div key={el.flow} className={`row ${el.flow}`}>
                <span>{el.flow}</span>
                <span>{el.code}</span>
              </div>
            ))}
            <input
              placeholder="Code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            ></input>
            <button
              onClick={handlePay}
              disabled={!decryptedData?.amount || !verificationCode}
            >
              Pay {decryptedData?.amount} {decryptedData?.currency}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Threedsv;
