// Endeavour intergration

import React, { useEffect, useState } from "react";
import "./endv-3dsv.scss";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";

const publicUrl = "https://api.fingenom.com";

function Endv3dsv() {
  const { txid } = useParams();
  const [creq, setCreq] = useState("");

  useEffect(() => {
    if (!txid) {
      toast.error("Invalid transaction id");
      return;
    }

    pay3dsreq();
  }, []);

  const pay3dsreq = async () => {
    const browserMetaData = {
      browserIP: "192.168.1.107",
      browserUserAgent: navigator.userAgent,
      browserLanguage: navigator.language,
      browserJavaEnabled: navigator.javaEnabled(),
      browserScreenWidth: window.screen.width,
      browserScreenHeight: window.screen.height,
      browserColorDepth: window.screen.colorDepth,
      browserTZ: new Date().getTimezoneOffset(),
    };

    try {
      const response = await fetch(
        `${publicUrl}/fn-execute/endv/create-tx-req`,
        {
          method: "POST",
          body: JSON.stringify({ oid: txid, browserMetaData }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (!result) return;
      
      setCreq(result.data.creq);
      setTimeout(() => {
        const creqForm = document.getElementById("creq-form");
        if (creqForm && typeof creqForm.submit === "object") {
          creqForm.submit.remove();
        }
        creqForm.submit();
      }, 1000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="endv3dsv">
      <PuffLoader color="#36d7b7" />
      <form
        autoComplete="off"
        action="https://www.threedsecurempi.com/EMVTDS/AUT?Action=ProcessCReq"
        method="post"
        id="creq-form"
      >
        <input type="hidden" name="creq" value={creq} />
      </form>
    </div>
  );
}

export default Endv3dsv;
