import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import PuffLoader from "react-spinners/PuffLoader";

import "./lvtp.scss";

function Livit() {
  const { txid } = useParams();
  const publicUrl = "https://api.fingenom.com";

  useEffect(() => {
    if (!txid) {
      toast.error("Invalid transaction id");
      return;
    }

    authReq();
  }, []);

  const authReq = async () => {
    try {
      const response = await fetch(
        `${publicUrl}/fn-execute/lvtp/auth-params/${txid}`
      );

      const result = await response.json();
      if (!result.encryptedBody) return;

      const bytes = CryptoJS.AES.decrypt(
        result.encryptedBody,
        process.env.REACT_APP_LIVIT_BODY_ENCRYPT_KEY
      );
      const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
      if (!decryptedText) return;
      prepareForm(JSON.parse(decryptedText));
    } catch (error) {
      toast.error("Invalid transaction");
    }
  };

  const prepareForm = (obj) => {
    let form = document.createElement("form");
    form.setAttribute("autoComplete", "off");
    form.setAttribute(
      "action",
      "https://one.smartwallets.tech/api/bkn/index.php"
    );
    form.setAttribute("method", "POST");
    form.setAttribute("id", "auth-form");
    for (let param in obj) {
      if (obj.hasOwnProperty(param)) {
        let input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", param);
        input.setAttribute("value", obj[param]);
        form.appendChild(input);
      }
    }
    document.body.appendChild(form);
    submitForm();
  };

  const submitForm = () => {
    setTimeout(() => {
      const authForm = document.getElementById("auth-form");
      if (authForm && typeof authForm.submit === "object") {
        authForm.submit.remove();
      }
      authForm.submit();
    }, 1000);
  };

  return (
    <div className="pl">
      <PuffLoader color="#36d7b7" />
    </div>
  );
}

export default Livit;
