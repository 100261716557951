import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './index.css';
import Gateway from "./pages/gateway/Gateway";
import Threedsv from './pages/3dsv/threedsv';
import Pl from './pages/pl/pl';
import Endv3dsv from './pages/endv-3dsv/endv-3dsv';
import { ToastContainer } from "react-toastify";
import TwoSteps3DSV from './pages/two-steps-3dsv/two-steps-3dsv';
import Livit from './pages/lvtp/lvtp';
import Sfp from './pages/sfp/sfp';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <ToastContainer />
    <BrowserRouter>
      <Routes>
        <Route path="payment/:env/:refno" element={<Gateway />}></Route>
        <Route path="purchase-3dsv/:txid" element={<Threedsv />}></Route>
        <Route path="pl/:txid" element={<Pl />}></Route>
        <Route path="endv3dsv/:txid" element={<Endv3dsv />}></Route>
        <Route path="tws3dsv/:txid" element={<TwoSteps3DSV />}></Route>
        <Route path="lvtp/:txid" element={<Livit />}></Route>
        <Route path="sfp/:env/:txid" element={<Sfp />}></Route>
      </Routes>
    </BrowserRouter>
  </>
);

