// Paylinkio intergration


import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";

import "./pl.scss";

function Pl() {
  const { txid } = useParams();
  const publicUrl = "https://api.fingenom.com";

  useEffect(() => {
    if (!txid) {
      toast.error("Invalid transaction id");
      return;
    }

    authReq();
  }, []);

  const authReq = async () => {
    try {
      const response = await fetch(
        `${publicUrl}/fn-execute/pl/auth-params/${txid}`
      );

      const result = await response.json();
      if (!result) return;

      const bytes = CryptoJS.AES.decrypt(result.hash, "pl-auth-params");
      const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
      if (!decryptedText) return;

      prepareForm(JSON.parse(decryptedText));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const prepareForm = (obj) => {
    let form = document.createElement("form");
    form.setAttribute("autoComplete", "off");
    form.setAttribute("action", obj.action);
    form.setAttribute("method", obj.method);
    form.setAttribute("id", "auth-form");
    for (let param in obj.params) {
      if (obj.params.hasOwnProperty(param)) {
        let input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", param);
        input.setAttribute("value", obj.params[param]);
        form.appendChild(input);
      }
    }
    document.body.appendChild(form);
    submitForm();
  };

  const submitForm = () => {
    setTimeout(() => {
      const authForm = document.getElementById("auth-form");
      if (authForm && typeof authForm.submit === "object") {
        authForm.submit.remove();
      }
      authForm.submit();
    }, 1000);
  };

  return <div className="pl"></div>;
}

export default Pl;
