// PSP MDZ1 intergration

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PuffLoader from "react-spinners/PuffLoader";

import "./two-steps-3dsv.scss";

function TwoSteps3DSV() {
  const { txid } = useParams();
  const publicUrl = "https://api.mdrpsp.com";

  useEffect(() => {
    if (!txid) {
      toast.error("Invalid transaction id");
      return;
    }

    pay3dsreq();
  }, []);

  const pay3dsreq = async () => {
    const browserMetaData = {
      browserIP: "192.168.1.107",
      browserUserAgent: navigator.userAgent,
      browserLanguage: navigator.language,
      browserJavaEnabled: navigator.javaEnabled(),
      browserScreenWidth: window.screen.width,
      browserScreenHeight: window.screen.height,
      browserColorDepth: window.screen.colorDepth,
      browserTZ: new Date().getTimezoneOffset(),
    };

    try {
      const response = await fetch(`${publicUrl}/fn-execute/psp/purchase`, {
        method: "POST",
        body: JSON.stringify({ oid: txid, browserMetaData }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      if (!result) return;
      if(result?.url){
        window.location.href = result.url;
      }
    } catch (error) {}
  };


  return (
    <div className="pl">
      <PuffLoader color="#36d7b7" />
    </div>
  );
}

export default TwoSteps3DSV;
